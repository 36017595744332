export default `<svg viewBox="0 0 49 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M39.132 6.71158C40.9298 6.71158 42.6601 8.60077 44.1354 9.61699C44.6577 9.97645 44.7886 10.6913 44.4296 11.2143C44.0706 11.7373 43.3566 11.8683 42.8343 11.5089C41.7437 10.7575 40.4628 9.00888 39.132 9.00888C37.8013 9.00888 36.5204 10.7575 35.4298 11.5089C34.9075 11.8683 34.1935 11.7359 33.8345 11.2143C33.4755 10.6927 33.6064 9.97645 34.1287 9.61699C35.604 8.60077 37.3343 6.71158 39.132 6.71158Z" fill="url(#paint0_linear_3263_6800)"/>
<path d="M2.18116 0H5.82366C6.7525 0 7.50482 0.745412 7.50482 1.66572V5.2748C7.50482 6.19511 8.25714 6.94052 9.18598 6.94052H12.8285C13.7573 6.94052 14.5096 7.68593 14.5096 8.60624V12.2153C14.5096 13.1356 13.7573 13.881 12.8285 13.881H9.18598C8.25714 13.881 7.50482 13.1356 7.50482 12.2153V8.60624C7.50482 7.68593 6.7525 6.94052 5.82366 6.94052H2.18116C1.25232 6.94052 0.5 6.19511 0.5 5.2748V1.66572C0.5 0.745412 1.25232 0 2.18116 0Z" fill="url(#paint1_linear_3263_6800)"/>
<path d="M6.10386 17.3513C6.10386 16.2014 5.16301 15.2691 4.00241 15.2691C2.84181 15.2691 1.90096 16.2014 1.90096 17.3513C1.90096 18.5012 2.84181 19.4335 4.00241 19.4335C5.16301 19.4335 6.10386 18.5012 6.10386 17.3513Z" fill="url(#paint2_linear_3263_6800)"/>
<path d="M24.5597 17.8305C22.6936 17.8305 20.8976 17.2781 19.3663 16.2342C18.8241 15.865 18.6883 15.1307 19.0609 14.5935C19.4336 14.0563 20.1747 13.9216 20.7168 14.2909C21.8488 15.0626 23.1783 15.4707 24.5597 15.4707C25.941 15.4707 27.2706 15.0626 28.4025 14.2909C28.9447 13.9216 29.6858 14.0577 30.0585 14.5935C30.4311 15.1293 30.2938 15.865 29.7531 16.2342C28.2218 17.2781 26.4258 17.8305 24.5597 17.8305Z" fill="url(#paint3_linear_3263_6800)"/>
<defs>
<linearGradient id="paint0_linear_3263_6800" x1="4" y1="2.51953" x2="38.5" y2="20.5195" gradientUnits="userSpaceOnUse">
<stop stop-color="#4F46E5"/>
<stop offset="1" stop-color="#2C277F"/>
<linearGradient id="paint1_linear_3263_6800" x1="4.55855" y1="2.02927" x2="39.5635" y2="20.2928" gradientUnits="userSpaceOnUse">
<stop stop-color="#4F46E5"/>
<stop offset="1" stop-color="#2C277F"/>
</linearGradient>
<linearGradient id="paint2_linear_3263_6800" x1="4.55855" y1="2.02927" x2="39.5635" y2="20.2928" gradientUnits="userSpaceOnUse">
<stop stop-color="#4F46E5"/>
<stop offset="1" stop-color="#2C277F"/>
</linearGradient>
<linearGradient id="paint3_linear_3263_6800" x1="4.55855" y1="2.02927" x2="39.5635" y2="20.2928" gradientUnits="userSpaceOnUse">
<stop stop-color="#4F46E5"/>
<stop offset="1" stop-color="#2C277F"/>
</linearGradient>
</defs>
</svg>
`;
