import userflow from 'userflow.js';
import { UserDTO } from '../services/userDataService';
import { AccountLanguage } from './useLanguageService';
import { envs } from '../env';
import { useScreenSizes } from './useScreenSizes';
import { useHandleError } from './logErrors';
const VITE_USERFLOW_TOKEN = envs.VITE_USERFLOW_TOKEN;
const VITE_LINKMATE_PREFIX = envs.VITE_LINKMATE_PREFIX;

let hasBeenInitialized = false;

export function useUserflow() {
  const { throwError } = useHandleError();
  const init = () => {
    callOrSkip(() => interalInit());
  };

  //TODO delete when we will have removed the useflow help page used inside the empire onboarding
  const openHelp = (language: AccountLanguage | 'onboarding') => {
    const helpUserflowHelpMap: Record<Exclude<AccountLanguage, undefined> | 'onboarding', string> = {
      en: 'f7f7608c-a942-45df-b548-887461741cbc',
      de: 'a5d539c5-46b8-403d-8213-ac7c6cb7236a',
      es: '617a3865-0bec-43c6-ab84-4800feef4777',
      mx: '617a3865-0bec-43c6-ab84-4800feef4777',
      it: 'f7f7608c-a942-45df-b548-887461741cbc',
      sv: 'f7f7608c-a942-45df-b548-887461741cbc',
      nl: 'f7f7608c-a942-45df-b548-887461741cbc',
      fr: 'f7f7608c-a942-45df-b548-887461741cbc',
      pt: 'f7f7608c-a942-45df-b548-887461741cbc',
      ja: 'f7f7608c-a942-45df-b548-887461741cbc',
      ro: 'f7f7608c-a942-45df-b548-887461741cbc',
      fi: 'f7f7608c-a942-45df-b548-887461741cbc',
      cs: 'f7f7608c-a942-45df-b548-887461741cbc',
      id: 'f7f7608c-a942-45df-b548-887461741cbc',
      ar: 'f7f7608c-a942-45df-b548-887461741cbc',
      onboarding: 'e5f76871-8eec-4d1a-83a2-b4f11923b293',
    };
    const userflowId = language ? helpUserflowHelpMap[language] : helpUserflowHelpMap['en'];
    callOrSkip(() => userflow.start(userflowId));
  };

  const openWelcome = (language: AccountLanguage | undefined) => {
    const { isMobile } = useScreenSizes();
    let languageUserflowWelcomeMap: {
      mobile: Record<Exclude<AccountLanguage, undefined>, string>;
      desktop: Record<Exclude<AccountLanguage, undefined>, string>;
    } = {
      desktop: {
        en: 'd97148dc-1481-41dc-918b-cfdd8ce42cd5',
        sv: 'd97148dc-1481-41dc-918b-cfdd8ce42cd5',
        de: 'f7691ffe-7369-4525-9f03-17a61ad8f466',
        es: '6737b093-787d-4283-b41e-b5a10f87ced0',
        mx: '6737b093-787d-4283-b41e-b5a10f87ced0',
        it: '64d9e94d-b871-4777-ab6e-4fd4e1cdbeda',
        nl: 'a94177ff-391b-42f8-9c23-4249896e7635',
        fr: 'd97148dc-1481-41dc-918b-cfdd8ce42cd5',
        pt: 'd97148dc-1481-41dc-918b-cfdd8ce42cd5',
        ja: 'd97148dc-1481-41dc-918b-cfdd8ce42cd5',
        ro: 'd97148dc-1481-41dc-918b-cfdd8ce42cd5',
        fi: 'd97148dc-1481-41dc-918b-cfdd8ce42cd5',
        cs: 'd97148dc-1481-41dc-918b-cfdd8ce42cd5',
        id: 'd97148dc-1481-41dc-918b-cfdd8ce42cd5',
        ar: 'd97148dc-1481-41dc-918b-cfdd8ce42cd5',
      },
      mobile: {
        en: '956c0625-0087-43e0-b9ef-ebe6dc555864',
        sv: '956c0625-0087-43e0-b9ef-ebe6dc555864',
        de: '41adc35f-feb0-4a1d-b048-f55943843653',
        es: '20a04416-6f48-41f4-90b1-70e189060a23',
        mx: '20a04416-6f48-41f4-90b1-70e189060a23',
        it: 'fe3d574a-f353-45fc-859b-cfba78a85798',
        nl: '947ee25a-63a2-42f2-b00a-b68fd2c05331',
        fr: '956c0625-0087-43e0-b9ef-ebe6dc555864',
        pt: '956c0625-0087-43e0-b9ef-ebe6dc555864',
        ja: '956c0625-0087-43e0-b9ef-ebe6dc555864',
        ro: '956c0625-0087-43e0-b9ef-ebe6dc555864',
        fi: '956c0625-0087-43e0-b9ef-ebe6dc555864',
        cs: '956c0625-0087-43e0-b9ef-ebe6dc555864',
        id: '956c0625-0087-43e0-b9ef-ebe6dc555864',
        ar: '956c0625-0087-43e0-b9ef-ebe6dc555864',
      },
    };

    const userflowMap = isMobile.value ? languageUserflowWelcomeMap.mobile : languageUserflowWelcomeMap.desktop;
    const userflowId = language ? userflowMap[language] : userflowMap['en'];
    callOrSkip(() => userflow.start(userflowId));
  };

  const identify = (language: AccountLanguage, user: UserDTO) => {
    callOrSkip(() =>
      userflow.identify(user.id.toString(), {
        name: user.firstName,
        email: user.email,
        signed_up_at: user.createdAt,
        locale_code: language,
      })
    );
  };

  const callOrSkip = (callback: () => void) => {
    try {
      const isTesting = window.sessionStorage.getItem('isTesting') === 'true';
      if (isTesting) {
        console.info('SKIPPING userflow because TESTING');
        return;
      }
    } catch (error) {
      throwError(error);
    }
    if (window.location.href.startsWith(VITE_LINKMATE_PREFIX)) {
      console.info('SKIPPING userflow because in linkmate');
      return;
    }
    interalInit();
    callback();
  };

  const interalInit = () => {
    if (!hasBeenInitialized) {
      userflow.init(VITE_USERFLOW_TOKEN);
      hasBeenInitialized = true;
    }
  };

  return {
    openHelp,
    identify,
    init,
    openWelcome,
  };
}
