export default `<svg viewBox="0 0 49 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M46.8188 20.8216H43.1763C42.2475 20.8216 41.4952 20.0761 41.4952 19.1558V15.5468C41.4952 14.6265 40.7429 13.881 39.814 13.881H36.1715C35.2427 13.881 34.4904 13.1356 34.4904 12.2153V8.60624C34.4904 7.68593 35.2427 6.94052 36.1715 6.94052H39.814C40.7429 6.94052 41.4952 6.19511 41.4952 5.2748V3.05383C41.4952 2.13352 42.2475 1.3881 43.1763 1.3881H45.4179C46.3467 1.3881 47.099 2.13352 47.099 3.05383V5.2748C47.099 6.19511 46.3467 6.94052 45.4179 6.94052H43.1763C42.2475 6.94052 41.4952 7.68593 41.4952 8.60624V12.2153C41.4952 13.1356 42.2475 13.881 43.1763 13.881H46.8188C47.7477 13.881 48.5 14.6265 48.5 15.5468V19.1558C48.5 20.0761 47.7477 20.8216 46.8188 20.8216Z" fill="url(#paint0_linear_3263_6800)"/>
<path d="M2.18116 0H5.82366C6.7525 0 7.50482 0.745412 7.50482 1.66572V5.2748C7.50482 6.19511 8.25714 6.94052 9.18598 6.94052H12.8285C13.7573 6.94052 14.5096 7.68593 14.5096 8.60624V12.2153C14.5096 13.1356 13.7573 13.881 12.8285 13.881H9.18598C8.25714 13.881 7.50482 13.1356 7.50482 12.2153V8.60624C7.50482 7.68593 6.7525 6.94052 5.82366 6.94052H2.18116C1.25232 6.94052 0.5 6.19511 0.5 5.2748V1.66572C0.5 0.745412 1.25232 0 2.18116 0Z" fill="url(#paint1_linear_3263_6800)"/>
<path d="M6.10386 17.3513C6.10386 16.2014 5.16301 15.2691 4.00241 15.2691C2.84181 15.2691 1.90096 16.2014 1.90096 17.3513C1.90096 18.5012 2.84181 19.4335 4.00241 19.4335C5.16301 19.4335 6.10386 18.5012 6.10386 17.3513Z" fill="url(#paint2_linear_3263_6800)"/>
<path d="M24.5597 17.8305C22.6936 17.8305 20.8976 17.2781 19.3663 16.2342C18.8241 15.865 18.6883 15.1307 19.0609 14.5935C19.4336 14.0563 20.1747 13.9216 20.7168 14.2909C21.8488 15.0626 23.1783 15.4707 24.5597 15.4707C25.941 15.4707 27.2706 15.0626 28.4025 14.2909C28.9447 13.9216 29.6858 14.0577 30.0585 14.5935C30.4311 15.1293 30.2938 15.865 29.7531 16.2342C28.2218 17.2781 26.4258 17.8305 24.5597 17.8305Z" fill="url(#paint3_linear_3263_6800)"/>
<defs>
<linearGradient id="paint0_linear_3263_6800" x1="4.55855" y1="2.02927" x2="39.5635" y2="20.2928" gradientUnits="userSpaceOnUse">
<stop stop-color="#4F46E5"/>
<stop offset="1" stop-color="#2C277F"/>
</linearGradient>
<linearGradient id="paint1_linear_3263_6800" x1="4.55855" y1="2.02927" x2="39.5635" y2="20.2928" gradientUnits="userSpaceOnUse">
<stop stop-color="#4F46E5"/>
<stop offset="1" stop-color="#2C277F"/>
</linearGradient>
<linearGradient id="paint2_linear_3263_6800" x1="4.55855" y1="2.02927" x2="39.5635" y2="20.2928" gradientUnits="userSpaceOnUse">
<stop stop-color="#4F46E5"/>
<stop offset="1" stop-color="#2C277F"/>
</linearGradient>
<linearGradient id="paint3_linear_3263_6800" x1="4.55855" y1="2.02927" x2="39.5635" y2="20.2928" gradientUnits="userSpaceOnUse">
<stop stop-color="#4F46E5"/>
<stop offset="1" stop-color="#2C277F"/>
</linearGradient>
</defs>
</svg>
`;
