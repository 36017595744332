export const NOTIFICATION_CONFIG = {
  DEFAULT_TITLE: 'New Lead Received',
  DEFAULT_BODY: 'Hey, you just received a new lead',
  DEFAULT_ICON: '/favicon.png',
  DEFAULT_BADGE: '/favicon.png',
  DEFAULT_URL: '/leadsPage',
} as const;

export const createNotificationOptions = (payload?: FCMPayload): NotificationOptions & { title: string } => {
  const title = payload?.notification?.title ?? NOTIFICATION_CONFIG.DEFAULT_TITLE;
  const body = payload?.notification?.body ?? NOTIFICATION_CONFIG.DEFAULT_BODY;
  const icon = payload?.notification?.icon ?? NOTIFICATION_CONFIG.DEFAULT_ICON;
  const badge = payload?.notification?.badge ?? NOTIFICATION_CONFIG.DEFAULT_BADGE;
  const url = payload?.data?.url ?? NOTIFICATION_CONFIG.DEFAULT_URL;

  return {
    title,
    body,
    icon,
    badge,
    data: {
      url,
      ...payload?.data,
    },
    requireInteraction: true,
    silent: false,
  };
};
