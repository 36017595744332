export { plugin } from '@formkit/vue';
import { createInput, defaultConfig } from '@formkit/vue';
import { uploadCloud } from '@formkit/icons';
import * as heroIcons from '@heroicons/vue/24/outline';
import * as countries from 'country-flag-icons/string/3x2';
import { VNode } from 'vue';
import { generateClasses } from '@formkit/themes';
import theme from '../assets/theme';
import * as customIcons from '../assets/icons';
import FilterDropdown from '../components/subcomponents/FilterDropdown.vue';
import BaseDropdown from '../components/subcomponents/BaseDropdown.vue';
import { de, es, it, sv, nl, fr, ja, pt, ro, id, cs, fi } from '@formkit/i18n';
import CountryCodeSelectorComponent from '@/components/subcomponents/CountryCodeSelectorComponent.vue';
import type { DefaultConfigOptions } from '@formkit/vue';

const buildHeroIconSvg = (node: VNode) => {
  let svg = '<';
  const type = node.type.toString();
  svg += type;

  for (const attr in node.props) {
    svg += ` ${attr}="${node.props[attr]}"`;
  }

  svg += ' >';

  if (node.children?.length) {
    const children = Array.prototype.slice.call(node.children);
    for (let i = 0; i < children.length; i++) {
      svg += buildHeroIconSvg(children[i]);
    }
  }

  svg += `<${type}/>`;
  return svg;
};

export function configureFormKit(): DefaultConfigOptions {
  return defaultConfig({
    config: {
      classes: generateClasses(theme) as Record<string, string>,
    },
    locales: {
      de,
      es,
      it,
      sv,
      nl,
      fr,
      ja,
      pt,
      ro,
      fi,
      cs,
      id,
    },
    icons: {
      uploadCloud,
      ...customIcons,
      ...countries,
    },
    inputs: {
      filterDropdown: createInput(FilterDropdown, { props: ['title', 'filterOptions', 'selected', 'multi'] }),
      baseDropdown: createInput(BaseDropdown, {
        props: [
          'selectOptions',
          'selected',
          'showValue',
          'selectedNotLabeled',
          'inputClass',
          'menuStyle',
          'itemStyle',
          'placeholder',
          'disabled',
        ],
      }),
      countryCode: createInput(CountryCodeSelectorComponent, { props: ['disabled', 'isRequired'] }),
    },
    iconLoader: (iconName: string) => {
      const keyVal = Object.entries(heroIcons).find(keyVal => keyVal[0] == iconName);

      if (!keyVal) {
        return undefined;
      }
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      const result = buildHeroIconSvg(keyVal[1]({}, {} as any));

      return new Promise(res => res(result));
    },
  });
}

export type FilterDropdownOption<T = string> = {
  label: string;
  value: T;
  icon?: string;
  subOptions?: FilterDropdownOption[];
  style?: string;
};
export type FilterDropdownSubOption = { option: FilterDropdownOption; parentOption: FilterDropdownOption };

export function isFilterDropdownSubOption(option: FilterDropdownOption | FilterDropdownSubOption): option is FilterDropdownSubOption {
  return (option as FilterDropdownSubOption).parentOption !== undefined;
}
